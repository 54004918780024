<template>
  <div class="left-session-1">
    <piechart
      :pieData="cyData"
      style="
        width: 420px;
        height: 240px;
        transform: scale(0.8) translateY(-100px);
      "
    ></piechart>
    <div class="labels">
      <div class="label-item">
        <div class="icon"></div>
        女
        <div class="value">
          {{ womanNumber }} ({{ womanNumber2 }})
        </div>
      </div>
      <div class="label-item"></div>
      <div class="label-item">
        <div class="icon"></div>
        男
        <div class="value">
          {{ manNumber }} ({{ manNumber2 }})
        </div>
      </div>
      <div class="label-item"></div>
    </div>
  </div>
</template>

<script>
import { numberToMoney } from "@/utils";

import piechart from "@/components/pieChart";

export default {
  components: { piechart },
  data() {
    return {
      cyData: [],
      manNumber: 0,
      womanNumber: 0,
      manNumber2: "0%",
      womanNumber2: "0%",
    };
  },
  props: {
    userProportion: {
      type: Object,
      default: () => ({}),
    },
  },
  methods: {
    init() {},
  },
  watch: {
    userProportion: {
      handler(newV, oldV) {
        if (newV && newV.sex) {
          console.log("_watch sex", newV);
          const data = newV;
          const denominator = data.sex.woman + data.sex.man;
          this.manNumber = numberToMoney(data.sex.man);
          this.manNumber2 = ((data.sex.man / denominator) * 100).toFixed(2) + "%";
          this.womanNumber = numberToMoney(data.sex.woman);
          this.womanNumber2 = ((data.sex.woman / denominator) * 100).toFixed(2) + "%";
          this.pieLengde = {
            top1: ((data.sex.woman / denominator) * 100).toFixed(2), // 市级协会
            top2: ((data.sex.man / denominator) * 100).toFixed(2), // 县市级协会
          };
          this.cyData = [
            {
              name: "女",
              value:
                denominator == 0 ? 0 : (data.sex.woman / denominator) * 100,
              itemStyle: {
                color: "#01f9cc",
              },
            },
            {
              name: "",
              value: 0,
              itemStyle: {
                color: "rgba(0,0,0,0)",
              },
            },
            {
              name: "男",
              value: denominator == 0 ? 0 : (data.sex.man / denominator) * 100,
              itemStyle: {
                color: "rgba(47,79,199,1)",
              },
            },
            {
              name: "",
              value: 0,
              itemStyle: {
                color: "rgba(33,176,178,1)",
              },
            },
          ];
        }
      },
      immediate: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.left-session-1 {
  color: #ffffff;
}

.labels {
  display: flex;
  align-items: center;
  justify-content: space-around;
  .label-item {
    display: flex;
    align-items: center;
    color: #ffffff;
    .icon {
      margin-right: 12px;
      width: 14px;
      height: 14px;
      background: #2c49b8;
    }
    .value {
      margin-left: 8px;
      font-size: 18px;
      font-family: PingFang SC, PingFang SC-400;
      font-weight: 400;
      text-align: LEFT;
      color: #37b2f5;
    }
  }
}
</style>
