<template>
  <div class="container">
    <div class="sportWrapper">
      <!-- 左侧 -->
      <div class="sportLeft">
        <!-- 选择区县 -->
        <el-select
          v-for="(select, index) in rankTopOptionsProps.selects"
          v-model="topSelectDate[index]"
          :key="index"
          placeholder="请选择区县"
          popper-class="content-top-select-popper"
          class="content-top-select"
          size="mini"
          @change="($value) => onSelectChange(select.name, $value)"
        >
          <el-option
            v-for="item in select.options"
            :key="item.key || item.value"
            :value="item.value"
            :label="item.label"
          >
          </el-option>
        </el-select>
        <div class="empty-block"></div>
        <ContentTop :title="areaName + '人流量男女比例分析'" :type="2" />
        <LeftSession1
          ref="leftSession1Ref"
          :userProportion="userProportion"
        ></LeftSession1>
        <div class="empty-block"></div>
        <ContentTop :title="areaName + '人流量年龄分析'" :type="2" />
        <LeftSession2 :userProportion="userProportion"></LeftSession2>
        <div class="empty-block"></div>
        <!-- <ContentTop :title="areaName + 'XXX分析'" :type="2" /> -->
        <LeftSession3></LeftSession3>
      </div>
      <!-- 中间 -->
      <div class="sportCenter">
        <CenterTop :code="code"></CenterTop>
        <Map :openRanking="openRanking" />
      </div>
      <!-- 右侧 -->
      <div class="sportRight">
        <ContentTop :title="areaName + '学校排行榜'" :type="2" />
        <RightSession1
          ref="rightSession1Ref"
          :schoolRanking="schoolRanking"
        ></RightSession1>
      </div>
    </div>
  </div>
</template>

<script>
import ContentTop from "@/components/contentTop";
import LeftSession1 from "./components/LeftSession1.vue";
import LeftSession2 from "./components/LeftSession2.vue";
import LeftSession3 from "./components/LeftSession3.vue";
import RightSession1 from "./components/RightSession1.vue";
import CenterTop from "./components/CenterTop.vue";
import Map from "./components/Map";

import service from "@/utils/request";

export default {
  components: {
    ContentTop,
    LeftSession1,
    LeftSession2,
    LeftSession3,
    CenterTop,
    RightSession1,
    Map,
  },
  computed: {
    areaName() {
      //   console.log("_", this.$store.state.areaList, this.matchRankParam.code);
      return (
        (
          this.$store.state.areaList.find(
            (item) => item.value == this.matchRankParam.code
          ) || {}
        ).label || ""
      );
    },
  },
  data() {
    return {
      code: "",
      schoolRanking: [],
      openRanking: [],
      userProportion: {},
      sportCount: {}, //  累积人次
      //   areaName: "",
      topSelectDate: [],
      matchRankParam: {
        code: "",
        type: 0,
      },
      rankTopOptionsProps: {
        selects: [
          {
            name: "one",
            options: this.$store.state.areaList.filter(
              (item) => item.label != "市本级"
            ),
          },
        ],
      },
    };
  },
  methods: {
    init() {
      console.log("_init", this.matchRankParam);
      this.initOpenRanking();
      this.initUserProportion();
      this.initRightSesion1();
      this.initSchoolRanking();
    },
    onSelectChange(name, value) {
      console.log("_onSelectChange", name, value);
      this.matchRankParam.code = value;
      this.code = value;
      //   this.$store.state.areaList.find((item) => item.value == value).name;
      this.init();
    },
    initRightSesion1() {
      this.$refs.rightSession1Ref && this.$refs.rightSession1Ref.init();
    },
    initSchoolRanking() {
      service
        .get(`/school/schoolRanking?area_code=${this.matchRankParam.code}`)
        .then((resp) => {
          console.log("_school/schoolRanking", resp.data);
          this.schoolRanking = resp.data.filter((item) => item.open_num > 0);
        });
    },
    initOpenRanking() {
      service
        .get(`/school/openRanking?area_code=${this.matchRankParam.code}`)
        .then((resp) => {
          console.log("_school/openRanking", resp.data);
          this.openRanking = resp.data;
        });
    },
    initUserProportion() {
      service
        .get(`/school/userProportion?area_code=${this.matchRankParam.code}`)
        .then((resp) => {
          this.userProportion = resp.data;
        });
      this.$refs.leftSession1Ref && this.$refs.leftSession1Ref.init();
    },
  },
  mounted() {
    this.init();
  },
};
</script>

<style lang="scss" scoped>
@import "../../assets/scss/index.scss";

.container {
  width: 100%;
  height: 100%;
  display: flex;
  padding-top: 16px;
  .sportWrapper {
    width: 100%;
    display: flex;
    .sportLeft {
      width: 450px;
      padding-bottom: 33px;
    }
    .sportCenter {
      flex: 1;
      display: flex;
      flex-direction: column;
      padding: 0 51px;
    }
    .sportRight {
      width: 444px;
      display: flex;
      flex-direction: column;
      padding-bottom: 33px;
    }
  }
}

.empty-block {
  width: 100%;
  min-height: 32px;
  background: transparent;
}

.content-top-select {
  width: 130px;
  margin-right: 10px;
  line-height: 100%;
  &:last-child {
    margin: 0;
  }
  ::v-deep input {
    padding: 2px 6px !important;
    font-size: 18px;
    background: transparent;
    border: 1px solid #4e8df9;
    border-radius: 2px;
  }
  ::v-deep .el-input--mini .el-input__inner {
    height: 42px;
    line-height: 42px;
  }
}

.content-top-select-popper.el-select-dropdown {
  background: rgba(48, 48, 48, 0.52);
  border: 1px solid rgba(78, 141, 249, 0.72);
  border-radius: 6px;
  backdrop-filter: blur(6px);
  overflow: hidden;
  padding: 0;
  .el-select-dropdown__item {
    font-size: 18px;
    height: 28px;
    line-height: 28px;
    &.selected,
    &.hover {
      background: rgba(48, 48, 48, 0.52);
    }
  }
}
</style>
