<template>
  <div class="left-session-3">
    <div class="bg-image"></div>
  </div>
</template>
<script>
export default {
  data() {
    return {};
  },
  methods: {
    init() {},
  },
};
</script>

<style lang="scss" scoped>
.left-session-3 {
  .bg-image {
    margin-left: 32px;
    width: 374px;
    height: 224px;
    background: url("../images/session3-image.png") no-repeat center/374px 224px;
  }
}
</style>
